import React from "react";
import { Text, Box, Button, Spacer } from "@chakra-ui/react";
import { PasswordInput, TextInput } from "src/components/MandalaUI/Inputs";
import { cloneDeep } from "lodash";
import { useAuthContext } from "src/contexts/auth";
import { GoogleSSOButton } from "src/components/MandalaUI/__SharedComponents__/GoogleSSOButton";
import { Link } from "src/components/MandalaUI/Link";

const _loginField = {
  username: "",
  password: ""
};

const LoginForm = () => {
  const {
    hanldeLogin,
    handleLoginWithGoogle,
    isLoginLoading,
    isLoginWithGoogleLoading,
    error,
    setError
  } = useAuthContext();
  const [loginField, setLoginField] = React.useState(_loginField);
  // error validation, show error when in the response API there is "username" || "password"
  // or "username" || "password" is empty
  const isUsernameError =
    (error && !(error.search(/username/i) < 0)) || !loginField.username;
  const isPasswordError =
    (error && !(error.search(/password/i) < 0)) || !loginField.password;

  const isFieldInvalid = !loginField.username || !loginField.password;

  const handleOnChange = ({ value, key }) => {
    const copyLoginField = cloneDeep(loginField);
    copyLoginField[key] = value;

    setLoginField(copyLoginField);

    // clean up error message from BE when change text input
    if (!error) {
      return;
    }
    setError();
  };

  const handleOnKeyPress = e => {
    if (isFieldInvalid) {
      return;
    }
    if (e.key === "Enter") {
      hanldeLogin(loginField);
    }
  };

  return (
    <>
      <TextInput
        wrapperProps={{
          pb: "1.5em"
        }}
        data-testid="username-field"
        variant="ddl-outline"
        label="Username"
        name="username"
        value={loginField.username}
        onChange={e => {
          handleOnChange({ value: e.target.value, key: "username" });
        }}
        onKeyPress={handleOnKeyPress}
        isInvalid={isUsernameError}
        isRequired={isUsernameError}
        errorMessage={error || "Username is required"}
      />
      <PasswordInput
        variant="ddl-outline"
        data-testid="password-field"
        label="Password"
        name="password"
        value={loginField.password}
        onChange={e => {
          handleOnChange({ value: e.target.value, key: "password" });
        }}
        onKeyPress={handleOnKeyPress}
        errorMessage={error || "Password is required"}
        isInvalid={isPasswordError}
        isRequired={isPasswordError}
      />
      <Spacer />
      <Button
        mt="1.5em"
        data-testid="login-button"
        w="100%"
        isLoading={isLoginLoading || isLoginWithGoogleLoading}
        isDisabled={isFieldInvalid || isLoginLoading}
        colorScheme="orange"
        variant="ddl-solid"
        fontSize="sm"
        onClick={() => hanldeLogin(loginField)}
      >
        Sign In
      </Button>
      <GoogleSSOButton handleLoginWithGoogle={handleLoginWithGoogle} />
      <Box textAlign="center" pt="2em">
        <Link variant="transparent" href="/forgot-password" fontSize="sm">
          Forgot Password?
        </Link>
        <Text pt="1em" color="mandala.black.500" fontSize="sm">
          Creating new account?
          <Link
            variant="transparent"
            href="/signup"
            ml=".25em"
            fontSize="inherit"
          >
            Sign Up
          </Link>
        </Text>
      </Box>
    </>
  );
};

export default LoginForm;

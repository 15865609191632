import React from "react";
import { LoginPage } from "src/ComponentPage/Login";
import { HeadTitle } from "src/components/MandalaUI/HeadTitle";

const LoginPageWrapper = () => (
  <React.Fragment>
    <HeadTitle title="Login" />
    <LoginPage />
  </React.Fragment>
);

export default LoginPageWrapper;

import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { createErrorToast } from "src/hooks/useToast";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";

// ref: button text https://github.com/MomenSherif/react-oauth#googlelogin
export const GOOGLE_SSO_BUTTON_LABEL_MAP = {
  SIGNIN_WITH: "signin_with",
  SIGNUP_WITH: "signup_with"
};

export const GoogleSSOButton = ({
  labelType = GOOGLE_SSO_BUTTON_LABEL_MAP.SIGNIN_WITH,
  handleLoginWithGoogle
}) => {
  const googleClientId = process.env.GOOGLE_CLIENT_ID;
  const hasGoogleClientId = Boolean(googleClientId);

  return hasGoogleClientId ? (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Box w="100%">
        <Flex align="center">
          <Divider />
          <Text padding="2" color="mandala.black.500" fontSize="sm">
            OR
          </Text>
          <Divider />
        </Flex>
        <GoogleLogin
          onSuccess={({ credential }) => handleLoginWithGoogle(credential)}
          onError={() =>
            createErrorToast(`Failed to ${labelType.replace("_", " ")} Google`)
          }
          width="350px"
          text={labelType}
          shape="circle"
          size="large"
          locale="en"
        />
      </Box>
    </GoogleOAuthProvider>
  ) : null;
};

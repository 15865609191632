import React from "react";
import NextLink from "next/link";
import { Link as _Link } from "@chakra-ui/react";

export const Link = ({ href, children, isDisabled, ...props }) => {
  if (isDisabled) {
    return <_Link {...props}>{children}</_Link>;
  }

  return (
    <NextLink href={href} passHref>
      <_Link {...props}>{children}</_Link>
    </NextLink>
  );
};

import React from "react";
import NextHead from "next/head";

export const HeadTitle = ({ title = "" }) => {
  return (
    <NextHead>
      <title>DDL - {title}</title>
    </NextHead>
  );
};
